/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ABEResponse, IAuthMarketingDetails, IErrorData, ILogin, IRedirectData } from '../../core/models/auth';
import { AuthService } from '../../core/services/auth.service';
import { ErrorService } from '../../core/services/error.service';
import * as translations from '../../shared/dictionary';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from '../../core/services';
import { Observable } from 'rxjs';


@Component({
  selector: 'wam-login',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginFormComponent implements OnInit {
  // login related variables
  rememberLogIn = true;
  challenge = '';
  inlineErrorDisplay: string;

  // form validation
  loginForm: FormGroup;
  emptyPassword = translations.LoginForm_EmptyPassword;
  emptyUserName = translations.LoginForm_emptyUserName;
  submitted = false;
  cookieExist = false;
  // show/hide password
  showPassword = false;
  srPasswordText = translations.LoginForm_PasswordHidden;
  passwordType = 'password';
  queryParams: Params;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected translations: any;
  domainName: string;
  signInUrl: string;
  customerCareNumber: string;
  showCustomerCareNumber: boolean = false;
  aci: string;
  locale: string;
  data: any;
  wayFURL: string;
  disable: boolean;
  showContact: boolean = false;
  disableSSOSignin: boolean = false;
  showLogin: boolean = true;
  showNext: boolean = false;
  oidcProxyDL: boolean = false;
  progressiveLogin: boolean;
  abeFlagEvaluationValue: boolean;
  aciExcludeFlagEvaluationValue: boolean;
  usernameCapturedFromQueryParameter: string;
  rememberPassedInFromQueryParameter: boolean;
  state: string;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private cookieService: CookieService,
    private appConfigService: AppConfigService
  ) {
    this.translations = translations;
    this.loginForm = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {

      this.getAuthMarketingDetails();
      this.route.queryParamMap.subscribe((params) => {
      this.oidcProxyDL = true;
      this.challenge = params.get('login_challenge');
      sessionStorage.setItem('challenge', this.challenge);
      this.usernameCapturedFromQueryParameter = params.get('username');
      this.rememberPassedInFromQueryParameter = params.get('remember') !== null;
      this.rememberLogIn = params.get('remember') === 'true';
      // aci and locale might get overridden by appContextData below, but not sure if that's a problem
      this.aci = params.get('aci');
      console.log('aci value from login form component'+this.aci);
      this.locale = params.get('locale');
      //
      console.log(`oidcProxyDL: ${this.oidcProxyDL}`);

      this.route.data.subscribe((value: any) => {
        console.log('AbeFlagValue:', value.abeFlagEvaluationValue);
        console.log('AciExcludeFlagEvaluationValue:', value.aciExcludeFlagEvaluationValue);
        this.abeFlagEvaluationValue = value.abeFlagEvaluationValue;

        this.progressiveLogin = value.abeFlagEvaluationValue && this.oidcProxyDL && !value.aciExcludeFlagEvaluationValue;

        console.log(`Progressive login: ${this.progressiveLogin}`);
        const isUsernameCaptured = this.usernameCapturedFromQueryParameter !== null && this.usernameCapturedFromQueryParameter.length > 0;
        this.updateForm(this.progressiveLogin, isUsernameCaptured);
        this.authService.setSignInPageUrl(`${window.location.origin}${this.router.url}`);
        if (this.authService.appContextData) {
          this.queryParams = {
            locale: this.authService.appContextData.ui_locales,
            aci: this.authService.appContextData.appContextId,
          };
          if (this.authService.appContextData.userName) {
            this.loginForm.patchValue({ userName: this.authService.appContextData.userName });
          }
          this.locale = this.authService.appContextData.ui_locales;
          this.aci = this.authService.appContextData.appContextId;
          if (this.locale === 'fr-FR' && this.aci === 'lint') {
            this.showContact = true;
          }

          this.state = this.authService.appContextData.state;
          sessionStorage.setItem('state', this.state);
        }
        if(isUsernameCaptured) {
          // setting this last so that it isn't overridden by the re-rendering of the form
          this.loginForm.patchValue({ userName: this.usernameCapturedFromQueryParameter }); // TODO check if there's a better way to do this
        }
        //
        if (this.cookieService.check('LAST_USED_IDP')) {
          this.signInWithLastUsedIdp(this.challenge, this.authService.appContextData.appContextId);
          this.cookieExist = true;
        }
      });
    });
  }

  /**
   * Update login form
   * @param progressiveLogin true if progressive login is being used
   * @param isUsernameCaptured true if username is captured from the query parameters
   */
  updateForm(progressiveLogin: boolean, isUsernameCaptured: boolean): void {
    this.loginForm = this.fb.group({
      userName: [{ value: '', disabled: progressiveLogin && isUsernameCaptured }, Validators.required],
      password: [{ value: '', disabled: progressiveLogin && !isUsernameCaptured }, Validators.required],
    });
  }

  get userNameControl(): FormControl {
    return this.loginForm.get('userName') as FormControl;
  }

  get passwordControl(): FormControl {
    return this.loginForm.get('password') as FormControl;
  }

  signIn(): void {
    if (this.loginForm.valid && this.challenge !== '') {
      this.disable = true;
      setTimeout(() => {
        this.disable = false;
      }, 5000);
      console.log('challenge', this.challenge);
      const usernameToUse =
        this.progressiveLogin && this.usernameCapturedFromQueryParameter
          ? this.usernameCapturedFromQueryParameter
          : this.loginForm.value.userName;
      this.authService.login(usernameToUse, this.loginForm.value.password, this.challenge, this.rememberLogIn).subscribe(
        (response: IRedirectData) => {
          this.authService.redirect(response.redirectUrl);
        },
        (error: IErrorData) => {
          if (error.status === 403 && error.error.errorCode === '403.9') {
            this.errorService.redirectToAccessUrl(error);
          } else if (error.status === 409) {
            const data = JSON.parse(sessionStorage.getItem('clientData'));
            this.authService.redirect(data.landingPageURL);
          } else {
            this.setCustomerCareNumber();
            this.showCustomerCareNumber = this.errorService.isRequiresSupportMessage(error);
            this.errorService.getErrorMessage(error);
            this.errorService.inlineError.subscribe((msg) => {
              this.inlineErrorDisplay = msg;
              this.disable = false;
            });
          }
          this.submitted = false;
        }
      );
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  sso(): void {
    this.router.navigate(['login-sso'], {
      queryParams: {
        aci: this.authService.appContextData.appContextId,
        locale: this.authService.appContextData.ui_locales,
      },
    });
  }

  keepLoggedInToggle($event: boolean): void {
    this.rememberLogIn = $event;
  }

  togglePasswordMask(): void {
    this.showPassword = !this.showPassword;

    this.passwordType = this.showPassword ? 'text' : 'password';

    // text for screen reader only
    this.srPasswordText = this.showPassword ? translations.LoginForm_PasswordShown : translations.LoginForm_PasswordHidden;
  }

  signInWithLastUsedIdp(challenge: string, aci: string) {
    this.authService.lastUsedIdpLogin(challenge, aci).subscribe((response: ILogin) => {
      this.domainName = this.myCustomDecode(response.domain);
      this.signInUrl = response.signInUrl;
    });
  }

  lastSignInRedirectToDomain() {
    window.location.href = this.signInUrl;
  }

  private setCustomerCareNumber() {
    if (this.authService.clientData && this.authService.clientData.csPhoneNumber) {
      this.customerCareNumber = this.authService.clientData.csPhoneNumber;
    }
  }

  private myCustomDecode(input: string): string {
    // Replace custom escape sequence with spaces
    if (input != null) {
      return input.replace(/-/g, ' ');
    }
    return '';
  }

  getAuthMarketingDetails(): void {
    this.locale = this.authService.appContextData.ui_locales;
    this.aci = this.authService.appContextData.appContextId;
    this.authService.getAuthMarketingDetails(this.aci, this.locale).subscribe(
      (response: IAuthMarketingDetails[]) => {
        this.data = response;
        if (this.data.wayfURL != null) {
          this.wayFURL = this.data.wayfURL;
        }
      },
      (err: IErrorData) => {
        console.log('errors');
      }
    );
  }

  /**
   * Progressive login ABE evaluation should be enabled AND OIDC Proxy dark launch should be true for progressive login to be used
   * @param oidcProxyDL boolean flag indicating if OIDC Proxy dark launch is enabled
   * @returns Observable for boolean flag indicating if progressive login is to be used
   */
  useProgressiveLogin(oidcProxyDL: boolean): Observable<boolean> {
    const abeEvaluationName = `${this.appConfigService.progressiveLoginABEEvaluationName}`;
    return this.authService.getAbeFlagDetails(abeEvaluationName).pipe(
      map((response: ABEResponse) => {
        console.log(`${abeEvaluationName} enabled: ${response.abeFlagValue}`);
        console.log('AbeFlagValue:', response.abeFlagValue, 'oidcProxyDL:', oidcProxyDL);
        return response.abeFlagValue && oidcProxyDL;
      })
    );
  }
}
